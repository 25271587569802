/* @imports must always all come first */

@import '../stylesheets/fonts/raleway';
@import '../stylesheets/fonts/roboto';
@import '../stylesheets/fonts/inter';

@import "tailwindcss/base";

/* Customisations go here */
@import '../stylesheets/_base.css';
@import '../stylesheets/_donut_style.css';
@import '../stylesheets/_dashboard.css';
@import '../stylesheets/_results.css';
@import '../stylesheets/_assessments.css';
@import '../stylesheets/_markdown.css';
@import '../stylesheets/_modal.css';
@import '../stylesheets/_tooltip.css';
@import '../stylesheets/_resources.css';
@import '../stylesheets/_recommendations.css';
@import '../stylesheets/_dropdown.css';
@import '../stylesheets/_action_plan_items.css';
@import '../stylesheets/_setup.css';
@import '../stylesheets/components/_organisation_form';

@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Some basic styles for rendering markdown.
   Adapted from https://github.com/tailwindcss/docs/issues/409 */
.markdown {
  @apply leading-snug;
  & > * + *,  & li + li, & li > p + p {
    @apply mt-6;
  }
  & strong {
    @apply text-black font-semibold;
  }
  & a {
    @apply text-blue-dark-sky underline font-medium;
  }
  & strong a {
    @apply font-semibold;
  }
  & h2 {
    @apply leading-tight text-xl font-bold text-black mb-2 mt-10;
  }
  & h3 {
    @apply leading-tight text-lg font-bold text-black mt-8 -mb-2;
  }
  & code {
    @apply font-mono text-sm inline bg-grey px-1;
  }
  & pre code {
    @apply block bg-black p-4 rounded;
  }
  & blockquote {
    @apply border-l-4 border-grey pl-4 italic;
  }
  & ul, & ol {
    li {
      @apply mt-0;
    }
  }
  /* Custom green bullet points */
  & ul li::before {
    content: "\2022";
    @apply text-green text-xl font-black pr-2 leading-4;
  }
  & ol {
    @apply pl-4;
    li {
      @apply list-decimal;
    }
  }
}

.title {
  @apply text-black font-bold mb-5 leading-tight;

  &.large {
    @apply text-xl md:text-2xl lg:text-3xl;
  }
}

.button {
  @apply inline-block px-4 py-3 my-1 rounded font-semibold text-sm cursor-pointer;
  &.green {
    @apply bg-green text-white;
  }
  &.black {
    @apply bg-black text-white;
  }
  &.white {
    @apply bg-white text-black;
  }
  &.red {
    @apply bg-red-score text-white;
  }
  &.grey {
    @apply bg-grey text-white;
  }
  &.purple {
    @apply bg-purple-dark text-white;
  }
  &.disabled {
    @apply text-opacity-50 cursor-default;
  }
  .rainbow-layout & {
    @apply bg-black text-white shadow-emboss-dark-bg;
  }
}

/* QR code */
.qr-code svg {
  @apply mx-auto;
}

/* For responsive embedded videos. */
.video-container {
  @apply relative h-0 max-w-full overflow-hidden;
  padding-bottom: 56.25%;
  iframe {
    @apply absolute w-full h-full top-0 left-0;
  }
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

/* Simple form styles, e.g. for Devise forms. */
.devise-form, .simple-form {
  .field {
    @apply mb-4 leading-tight;
  }

  label {
    @apply font-semibold mb-1;
  }

  input[type="text"], input[type="password"], input[type="email"] {
    @apply p-2 shadow-inner rounded-md w-full my-1;
  }

  .rainbow-layout & {
    input:not([type="submit"]) {
      @apply rounded-full my-2 text-center;
    }

    label {
      @apply font-bold mb-3;
      &::after {
        content: ':';
      }
    }
  }
}

.bg-gradient-green {
  background-image: linear-gradient(#33f585, #5bae7e);
}

.bg-gradient-orange {
  background-image: linear-gradient(#fad142, #ff7e00);
}

.bg-gradient-orange-dark {
  background-image: linear-gradient(#fba54b, #ff8301);
}

.bg-gradient-red {
  background-image: linear-gradient(#f98b86, #f71c12);
}

.bg-gradient-blue {
  background-image: linear-gradient(#a2dbe8, #50c2dc);
}

.bg-gradient-purple {
  background-image: linear-gradient(#9f74d9, #674792);
}

.bg-gradient-mid-purple {
  background-image: linear-gradient(#C8AFE9, #9475BD);
}

.bg-gradient-light-purple {
  background-image: linear-gradient(#E9DEF7, #B59FD1);
}

.bg-gradient-grey {
  background-image: linear-gradient(#d0d0d1, #b9bbbc);
}
/* For the engagement thermometer on the launched assessment dashboard. */
.bg-engagement-thermometer {
  background-image: linear-gradient(to top, theme('colors.yellow.dark') 0%, theme('colors.orange.dark') 100%);
}

/* Hiding the native increment/decrement arrows for number fields. */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}

@-moz-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}

@-o-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}

@-ms-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}

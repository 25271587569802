/* Modifier for expanded results sections */
.section-expanded .summary-arrow, .question-expanded .question-arrow, .summary-expanded .summary-arrow {
  @apply rotate-90;
}

.question-expanded {
  @apply shadow-bottom;
}

.comma-separated ~ .comma-separated::before {
  content: ', ';
}

/* Use to contain 2 side-by-side score bars within a certain 
 * space. Shared by both the sections and questions. */
.comparison-score-bar-area {
  width: 100%;

  @screen lg {
    width: 480px;
  }

  @screen xl {
    width: 580px;
  }
}

.tooltip-content[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip-content[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -4px;
}

.tooltip-content[data-popper-placement^='left'] > .tooltip-arrow {
  right: -4px;
}

.tooltip-content[data-popper-placement^='right'] > .tooltip-arrow {
  left: -4px;
}

.tooltip-content {
  /* Give the tooltip a z-index, because we use them around the score bars which
     have `position: absolute`, and the tooltips should still appear in front of
     them. Don't make it too high, we don't want the tooltips to appear in front
     of the navbar! */
  @apply z-10 bg-black text-white p-3 rounded-sm;
}

/* For our tooltip arrows */
.tooltip-arrow, .tooltip-arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
}

.tooltip-arrow::before {
  content: '';
  transform: rotate(45deg);
  @apply bg-black;
}

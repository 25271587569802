.card-container {
  &.out-of-focus .overlay {
    @apply absolute inset-0 rounded-sm;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75));
  }

  &.grey {
    @apply bg-grey-main;
  }

  &.yellow {
    background-image: linear-gradient(theme('colors.yellow.light'), theme('colors.yellow.dark'));
  }

  &.blue {
    background-image: linear-gradient(theme('colors.blue.dark-sky'), theme('colors.blue.light-sky'));
  }
}

.wizard {
  @apply fixed inset-0 bg-black bg-opacity-50;

  .modal-container {
    @apply fixed w-full;
    top: 130px;
  }

  .modal {
    max-width: 330px;
    @apply font-bold bg-white rounded-lg py-5 px-4 shadow-lg;
  }
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 90%;
  padding-bottom: 90%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}

.svg-container svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'),
       url('../fonts/inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
       url('../fonts/inter/Inter-SemiBold.ttf') format('truetype');
}

@layer components {
  .small-square {
    @apply col-span-2 row-span-1 text-sm pr-0; 
  }

  .wide-rectangle {
    @apply col-span-4 row-span-1 text-base pr-4;
  }

  .large-rectangle {
    @apply col-span-3 row-span-2 text-lg;
  }
}

/* The main resources grid, on the the index page. */
#resources-grid-test {
  grid-template-columns: repeat(4, minmax(0, 1fr));

  > .resource {
    @apply small-square;

    &:nth-of-type(2n) {
      @apply small-square;
    }
    &:nth-of-type(3n) {
      @apply wide-rectangle;
    }
    &:nth-of-type(4n) {
      @apply wide-rectangle;
    }
  }
}

@screen md {
  #resources-grid-test {
    grid-template-columns: repeat(9, minmax(0, 1fr));

    /* Define a grid pattern for 12 resources, repeatable with no gaps. */
    > .resource {
      @apply small-square;

      &:nth-of-type(12n+2) {
        @apply small-square;
      }
      &:nth-of-type(12n+3) {
        @apply large-rectangle;
      }
      &:nth-of-type(12n+4) {
        @apply small-square;
      }
      &:nth-of-type(12n+5) {
        @apply wide-rectangle;
      }
      &:nth-of-type(12n+6) {
        @apply small-square;
      }
      &:nth-of-type(12n+7) {
        @apply large-rectangle;
      }
      &:nth-of-type(12n+8) {
        @apply small-square;
      }
      &:nth-of-type(12n+9) {
        @apply wide-rectangle;
      }
      &:nth-of-type(12n+10), &:nth-of-type(12n+11), &:nth-of-type(12n+12) {
        @apply small-square;
      }
    }
  }
}

@screen lg {
  #resources-grid-test {
    grid-template-columns: repeat(14, minmax(0, 1fr));

    /* Define a grid pattern for 18 resources, repeatable with no gaps. */
    > .resource {
      &:nth-of-type(18n+1) {
        @apply small-square;
      }

      &:nth-of-type(18n+2) {
        @apply large-rectangle;
      }
      &:nth-of-type(18n+3), &:nth-of-type(18n+4) {
        @apply small-square;
      }
      &:nth-of-type(18n+5) {
        @apply large-rectangle;
      }
      &:nth-of-type(18n+6), &:nth-of-type(18n+7), &:nth-of-type(18n+9) {
        @apply small-square;
      }
      &:nth-of-type(18n+8), &:nth-of-type(18n+18) {
        @apply wide-rectangle;
        @apply pr-8;
      }
      &:nth-of-type(18n+10), &:nth-of-type(18n+13) {
        @apply large-rectangle;
      }
      &:nth-of-type(18n+11), &:nth-of-type(18n+12),
      &:nth-of-type(18n+14), &:nth-of-type(18n+15),
      &:nth-of-type(18n+16), &:nth-of-type(18n+17) {
        @apply small-square;
      }
    }
  }
}

/* The smaller sample grid that appears on the dashboard. */
#sample-resources-grid-test {
  grid-template-columns: repeat(4, minmax(0, 1fr));

  > .resource {
    @apply small-square;

    &:nth-of-type(2) {
      @apply small-square;
    }
    &:nth-of-type(3) {
      @apply col-span-4 row-span-2 text-base pr-4;
    }
  }
}

@screen xl {
  #sample-resources-grid-test {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    > .resource {
      @apply col-span-1 flex items-end text-sm;

      &:nth-of-type(2) {
        @apply col-span-2 row-span-2 text-lg pr-4; 
      }
      &:nth-of-type(3) {
        @apply col-span-1 row-span-1 text-sm;
      }
      &:nth-of-type(4) {
        @apply col-span-1 row-span-1 text-sm;
      }
    }
  }
}

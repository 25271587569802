/* Grey radio buttons that turn purple when they are checked. In some places it's
   the label we colour in, in others we use the .radio-checked-purple class
   because the label is elsewhere on the page. */
.grey-radio-button {
  input[type="radio"]:checked, input[type='checkbox']:checked {
    + label, + .radio-checked-purple {
      @apply bg-purple-dark shadow-inner-radio;
    }
  }

  input[type="radio"]:focus:not(:checked), input[type='checkbox']:focus:not(:checked) {
    + label, + .radio-checked-purple {
        @apply bg-grey-main;
    }
  }

  input[type="radio"]:checked:focus, input[type="checkbox"]:checked:focus {
    + label, + .radio-checked-purple {
      @apply bg-purple-darker;
    }
  }

  /* For tickbox questions on employee surveys, I'd like to act like the
     checkbox is being hovered when any of the label is hovered. Not sure if
     this will affect others too? */
  label:hover {
    .radio-checked-purple {
      @apply bg-grey-main;
    }
  }
}

/* Swanky sliding switches. */
.swanky-switch {
  .switch {
    @apply bg-grey-main-bg;
  }

  input[type="checkbox"], input[type="radio"] {
    &:checked + .switch {
      @apply bg-purple-dark;

      div {
        /* Magic number! Using 'left' positioning here rather than flex so that we
           can animate the transition with a nice smooth sliding effect. */
        left: calc(100% - 1.5rem);
      }
    }

    &:focus + .switch {
      @apply bg-grey-main;
    }

    &:checked:focus + .switch {
      @apply bg-purple-darker;
    }
  }
}

@screen md {
  .swanky-switch {
    input[type='checkbox'], input[type="radio"] {
      &:checked + .switch div {
        left: calc(100% - 2rem);
      }
    }
  }
}


/* For the purple radio buttons in the Employee Assessment Likert questions. */
.likert-inputs {
  input[type="radio"]:checked + .selector {
    @apply flex;
  }

  label:focus-within, label:hover {
    @apply bg-purple-darker;
  }
}

.about-the-organisation {
  input, select, textarea {
    @apply text-purple-dark font-semibold;
  }
}

/* Mobile styles for the assessment section navigation. */
.section-number-nav-item {
  @apply p-1;
  &:not(:last-child) {
    @apply border-b border-white border-opacity-25 mb-2;
  }
}

/* Desktop styles for the assessment section navigation. */
@screen md {
  .section-number-nav-item {
    @apply p-0;
    &:not(:last-child) {
      @apply border-0 mb-0;
    }
  }
}

/* Styles for the ethnic group dropdown that appears on the last page of the
   employee assessment, with the other demographic data fields. */
#ethnic-group-select {
  .group {
    @apply rounded-full;
  }
  .selection, .group-inner {
    @apply hidden;
  }
  .active {
    @apply bg-purple-dark text-white leading-none;
    .group-inner {
      @apply hidden;
      .option:hover {
        @apply bg-white text-purple-dark font-medium;
      }
    }
    .selection {
      @apply block;
    }
  }
  .open {
    @apply rounded-lg;
    .group-inner {
      @apply block;
    }
  }

  input[type="radio"]:focus + p {
    @apply text-purple-dark;
  }
}

/* checkbox tags */
ul.checkbox-tags {
  list-style: none;
}

ul.checkbox-tags li {
  display: inline;
  margin: 0;
}

ul.checkbox-tags li label {
  @apply bg-grey-5 text-grey-1;
  display: inline-block;
  border-radius: 5px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all .2s;
  cursor: pointer;
}

ul.checkbox-tags li input[type="checkbox"]:checked + label {
  @apply bg-black text-white;
  transition: all .2s;
}

ul.checkbox-tags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
